import { Typography } from '@mui/material'

export const AdminVersion = () => {
    const version = '2024.40.1'

    return (
        <>
            <Typography variant='h3'>Admin Version: {version}</Typography>
        </>
    )
}

export default AdminVersion
